import React, { useState } from "react";
import axios from "axios";

function SectionSearch() {
  const [searchtext, setSearchtext] = useState();
  const [results, setresults] = useState("");
  const [hasSearched, setHasSearched] = useState(false);

  const handleInputChange = (event) => {
    setSearchtext(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get("apiendpoint", {
        query: searchtext,
      });
      setresults(response.data.results);
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <>
      <div className="widget">
        <h4 className="widget-title ">Search</h4>
        <div className="search-bx">
          <form role="search" method="post" onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                name="news-letter"
                type="text"
                onChange={handleInputChange}
                className="form-control bg-gray"
                placeholder="Write your text"
              />
              <span className="input-group-btn">
                <button type="submit" className="btn">
                  <i className="flaticon-search" />
                </button>
              </span>
            </div>
          </form>
        </div>
        {hasSearched && results.length === 0  (
          <p>No results found</p>
        )}
        {results.length > 0 ? (
          <div className="search-results">
            <h4>Search Results:</h4>
            <ul>
              {results.map((item, index) => (
                <li key={index}>{item.title}</li>
              ))}
            </ul>
          </div>
        ) : (
          <p>No results found</p>
        )}
      </div>
    </>
  );
}
export default SectionSearch;
